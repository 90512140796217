import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Backdrop from '@mui/material/Backdrop';
import ContentPasteOffSharpIcon from '@mui/icons-material/ContentPasteOffSharp';
import classNames from 'classnames';
import {Table, TableBody, TableCell, IconButton, CircularProgress,
  Switch, Paper, TableRow, TableHead, TableContainer,
  Button, Dialog, DialogActions, DialogContent, 
  DialogContentText, DialogTitle} from '@mui/material';
import { toast } from "react-hot-toast";
import request from '../services';
import { useNavigate } from "react-router-dom";
  
  const DenseTable = ({musteriId, yeniHesap}) => {
    const navigate = useNavigate();
    const [hsModal, setHsModal] = React.useState(false);
    const [hSilSpinner, setHsilSpinner] = React.useState(true);
    const [hesap, setHesap] = React.useState({value:'',name:''});
    const [hesaplar, setHesaplar] = React.useState([]);
    const [disabled, setDisabled] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [backdrop, setBackdrop] = React.useState(false);
    
    const deleteHesap = ()=>{
      const toastId=toast.loading(hesap.name + ' Siliniyor lütfen bekleyin.')
      request.postAxios('Hesap/DeleteHesap?id='+hesap.value).then((res)=>{
        setHesaplar([...hesaplar.filter((item)=> item.id !== hesap.value)])
        toast.success( hesap.name + ' Başarılı bir şekilde silindi.',{id:toastId, duration:2500})
        setHsModal(false);
        setHsilSpinner(true)
      }).catch((err)=>{
        if(err?.response?.status === 401){
          window.location.href = '/login'
          return;
      }
        toast.error( hesap.name + ' Silinirken bir hata oluştu.',{id:toastId, duration:3000})
        setHsModal(false);
        setHsilSpinner(true)
      })
    }
    
    React.useEffect(()=>{
      if(musteriId){
        setBackdrop(true)
        request.getAxios('Hesap/GetHesaplar?musteriId=' + musteriId).then((res)=>{
          if(!(res.data.length > 0))
            setMessage('Lütfen bir hesap ekleyin!')
          setHesaplar(res.data);
          setBackdrop(false)
        }).catch((err)=>{
          if(err?.response?.status === 401){
            window.location.href = '/login'
            return;
          }
          setMessage('Hesaplar yüklenirken bir hata oluştu.')
          toast.error('Hesaplar yüklenirken bir hata oluştu.', {duration:3000})
          setBackdrop(false)
        })
      }
      else{
        setHesaplar([])
        setMessage('Lütfen bir müşteri seçin ya da ekleyin!')
      }
    },[musteriId, yeniHesap])

  function hesaplarUpdate(id, mod){
    const newHesaplar = hesaplar.map((item)=>{
      if(item.id !== id){
        return item;
      }else{
        return{...item, tatilMod:mod}
      }
    })

    setHesaplar(newHesaplar);
  }

    const tatilModChange= (id, mod, ad)=>{
      const toastId = toast.loading(ad + (mod ? 
        ' Tatil modundan çıkartılıyor.': ' Tatil moduna alınıyor.'));
      setDisabled(true);
      request.postAxios('Hesap/ChangeTatilMod?hesapId='+id+'&durum='+!mod).then((res)=>{
        hesaplarUpdate(id, !mod);
        toast.success(ad + (mod ? ' Tatil modundan çıkartıldı.' : ' Tatil moduna alındı.'), {
          id: toastId,
          duration:2500
        });
        setDisabled(false)
      }).catch((err)=>{
        if(err?.response?.status === 401){
          window.location.href = '/login'
          return;
      }
        toast.error(ad + (mod ? ' Tatil modundan çıkartılamadı.' : ' Tatil moduna alınamadı.'), {
          id: toastId,
          duration:3000
        });
        setDisabled(false)
      })
    }

  return (
    <>
    <TableContainer component={Paper} className='position-relative'>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop} className='position-absolute' style={{backgroundColor:'#cfcfcf80'}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Ad</TableCell>
            <TableCell align='center'>Gönderilen Posta (Tüm)</TableCell>
            <TableCell align='center'>Gönderilen Posta (Gün)</TableCell>
            <TableCell align='center'>Okunma Sayısı</TableCell>
            <TableCell align='center'>Alıcı Sayısı</TableCell>
            <TableCell align='center'>Tatil Modu</TableCell>
            <TableCell align='center'>Düzenle</TableCell>
            <TableCell align='center'>Sil</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hesaplar.length > 0 ? (hesaplar?.map((row) => (
            <TableRow
              key={row.ad}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.ad}
              </TableCell>
              <TableCell align='center'>{row.gonderilenPosta}</TableCell>
              <TableCell align='center'>{row.gonderilenPostaGun}</TableCell>
              <TableCell align='center'>{row.okunmaSayisi}</TableCell>
              <TableCell align="center">
                    <span className={classNames(
                      {'text-danger': row.alici < 100 ? true : false},
                      {'text-warning': row.alici >= 100 & row.alici < 300 ? true : false},
                      {'text-success': row.alici >= 300 ? true : false}
                      )}>{row.alici}</span>
                </TableCell>
              <TableCell align='center' style={{width:'100px'}}>
              <Switch color="success" size="small" checked={row.tatilMod} 
              onChange={()=>tatilModChange(row.id, row.tatilMod, row.ad)} disabled={disabled}/>
                </TableCell>
              <TableCell align='center' style={{width:'74px'}}>
                <IconButton aria-label="edit" size='small' onClick={()=>navigate('/settings/'+ row.id)}>
                    <EditIcon fontSize="small"/>
                    </IconButton>
              </TableCell>
              <TableCell align='center' style={{width:'74px'}}>
                <IconButton aria-label="delete" size='small' color="error" 
                onClick={()=>{setHesap({value:row.id, name:row.ad}); setHsModal(true)}}>
                    <DeleteIcon fontSize="small"/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))) : (<TableRow>
              <TableCell align='center' colSpan={8}>
                 <span className='fs-3 text-black-50 d-flex justify-content-center'>
                 <ContentPasteOffSharpIcon fontSize='large' className='me-2'>
                   </ContentPasteOffSharpIcon>{message}
                 </span>
              </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>

          <div>
            <Dialog
                open={hsModal}
                //onClose={()=>setHsModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {hesap.name + " Silinecek"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {hesap.name + ' Altında bulunan tüm veriler silinecek.'+
                    ' Yine de silmek istiyor musunuz?'}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button disabled={!hSilSpinner} onClick={()=>setHsModal(false)}>İptal</Button>
                <Button onClick={()=>{deleteHesap(); setHsilSpinner(false)}} hidden={!hSilSpinner} autoFocus color='error'>
                      SİL
                </Button>
                <CircularProgress className='mx-4' hidden={hSilSpinner} color="error" size={24}/>
                </DialogActions>
            </Dialog> 
        </div>
    </>
  );
}

export default DenseTable;